import { InputNumber, Button, Table, Space, message, Form, Image, Spin, Select } from 'antd';
import constants from '../../constants/constants';
import React from "react";
import { useEffect } from 'react';
import APIUtils from '../../api/APIUtils';
import Cookies from 'js-cookie';
import WebLinks from '../../api/WebLinks';
import { useState } from 'react';
import { GetCustomPriorityLevelIcons, GetPriorityLevelColors, GetPriorityLevelIcons } from '../../globalFunctions/GlobalFunctions';
import { useContext } from 'react';
import { CurrencyContext } from '../../Context';

const MultiBudgetByCostCenter = (props) => {
	const currencyContext=useContext(CurrencyContext);
	const [Loading,setLoading]=useState(false);
	const [supplierList,setSupplierList]=useState([]);
	const [data,setData]=useState([]);
	const [total,setTotal]=useState(100);
	const [currentPage,setCurrentPage]=useState(1);
	const [supplierID,setSupplierID]=useState(null);
	const [pageSize,setPageSize]=useState(100);
	const [priorityCheck,setPriorityCheck]=useState("f");
	const [form] = Form.useForm();
	
	const budgetValColumns = [
		{
			title: <span className='uppercase font-bold'>This month</span>,
			align: "left",
			children:[
				{
					title:<span className='font-bold'>Budget</span>,
					key: 'thisMonth',
					dataIndex: 'thisMonth',
					width: '12.5%',
					render: (text, record, index) => (
						<>
							{index===0 || !record.overallBudget ? (
								<span className='font-extrabold'>{currencyContext.currency}{text}</span>) :
								(
                  <span className='font-extrabold'>{currencyContext.currency}{text}</span>
								)
                }
						</>
					),
				},
				{
					title:<span>(spend)</span>,
					key: 'thisMonthSpend',
					dataIndex: 'thisMonthSpend',
					width: '12.5%',
					render: (_,item) => (
						<Space size="small">
							<span>({currencyContext.currency}{item.thisMonthSpend})</span>
							{item.thisMonth>0?<span className={item.thisMonthSpend>0?'text-green-500 font-bold':'text-red-500 font-bold'}>{item.thisMonthSpentPer+"%" }</span>:null}
						</Space>
					),
				}
			]
		},
	];

	const BudgetCol =[
		{
			dataIndex: 'Buyer Name',
			key: 'buyerName',
			width: '15%',
			render: (text, record, index) => <>
				{index === 0 ? (<span className='uppercase font-extrabold text-lg'>Total Budget</span>) : <span className='uppercase font-bold text-gray-400'>{record.buyerName}</span>}
			</>
		},
		{
			dataIndex: 'budgetCategory',
			key: 'budgetCategory',
			width: '5%',
			render: (text, record, index) => <span className='font-bold text-gray-400'>
				{index===0?"":record.overallBudget ? '(Overall)' : '(By Priority)'}
			</span>,
		},
		{
			title: <span className='uppercase font-bold'>Last month</span>,
			align: "left",
			children:[
				{
					title:<span className='font-bold'>Budget</span>,
					dataIndex: 'lastMonth',
					key: 'lastMonth',
					render: (text) => <span className='font-extrabold'>{currencyContext.currency}{text}</span>
				},
				{
					title: <span>(spend)</span>,
					dataIndex:'lastMonthSpend',
					key:'lastMonthSpend',
					render:(_,item) =>
					<Space size="small">
						<span>({currencyContext.currency}{item.lastMonthSpend})</span>
						{item.lastMonth>0?<span className={item.lastMonthSpend>0?'text-green-500 font-bold':'text-red-500 font-bold'}>{item.lastMonthSpentPer+"%"}</span>:null}
					</Space>
				}
			]
		},
		...budgetValColumns,
		{
			title: <>
				<span className='uppercase font-bold'>Next month</span>
				<br/>
				<span className='font-bold'>Budget</span>
			</>,
			key: 'nextMonth',
			dataIndex: 'nextMonth',
			width: '25%',
			render: (text, record, index) => (
				<>
				{index===0 || !record.overallBudget ? (
								<span className='font-extrabold'>{currencyContext.currency}{text}</span>) :
					(<div>
            <span className='font-extrabold'>{currencyContext.currency}{text}</span>
					</div>)}
				</>
			),
		},
	];
	const columns = [...BudgetCol]
	// Priority expandable sub table.
	const expandedRowRender = (record,index, indent, expanded) => {
		const byPriorityColumns = [
			{
				dataIndex: 'Buyers',
				key: 'suppliers',
				width: '15.6%',
				render: (text) => <span className='uppercase font-bold invisible'>{text} Budget</span>,
			},
			{
				dataIndex: 'priorityLevel',
				key: 'priorityLevel',
				width: '12%',
				render: (text) => <Space>
					{/* {GetPriorityLevelIcons(text)} */}
					<div className={priorityCheck==="t"?GetCustomPriorityLevelIcons(text):GetPriorityLevelColors(text)}></div>
					<span>{text}</span>
				</Space>,
			},
			{
				title: <span className='uppercase font-bold'>Last month</span>,
				align: "left",
				children:[
					{
						title:<span className='font-bold'>Budget</span>,
						dataIndex: 'lastMonth',
						key: 'lastMonth',
						width: '8.5%',
						render: (text) => <span className='font-extrabold'>{currencyContext.currency}{text}</span>
					},
					{
						title: <span>(spend)</span>,
						dataIndex:'lastMonthSpend',
						key:'lastMonthSpend',
						width: '13%',
						render:(_,item) =>
						<Space size="small">
							<span>({currencyContext.currency}{item.lastMonthSpend})</span>
							{item.lastMonth>0?<span className={item.lastMonthSpend>0?'text-green-500 font-bold':'text-red-500 font-bold'}>{item.lastMonthSpentPer}%</span>:null}
						</Space>
					}
				]
			},
			{
				title: <span className='uppercase font-bold'>This month</span>,
				align: "left",
				children:[
					{
						title:<span className='font-bold'>Budget</span>,
						key: 'thisMonth',
						dataIndex: 'thisMonth',
						width: '13%',
						render: (text, record, index) => (
              <span>{text}</span>
							
						),
					},
					{
						title:<span>(spend)</span>,
						key: 'thisMonthSpend',
						dataIndex: 'thisMonthSpend',
						width: '13%',
						render:  (_,item) => (
							<Space size="small">
								<span>({currencyContext.currency}{item.thisMonthSpend})</span>
								{item.thisMonth>0?<span className={item.thisMonthSpend>0?'text-green-500 font-bold':'text-red-500 font-bold'}>{item.thisMonthSpentPer}%</span>:null}
							</Space>
						),
					}
				]
			},
			{
				title: <span className='uppercase font-bold'>Next month</span>,
				key: 'nextMonth',
				width: '26%',
				render: (text, record, index) => (
          <span>{text}</span>
					
				),
			},
		];
		const byPriorityData = record.priorityList;
		return <Table columns={byPriorityColumns} dataSource={byPriorityData} size="middle" showHeader={false} pagination={false} />;
	};
	const getSupplierList = async () =>{
		let suppliers=[];
		const response = await APIUtils.apiCall('GET',WebLinks.GetSubBuyerSuppliers);
		if(!response.isCatch && response.resultObj.statusCode==="2000"){
			response.resultObj?.data?.map((item,index)=>{
				item.value=item.supplierId;
				item.label=item.supplierName;
			})
			suppliers=response?.resultObj?.data;
      let tempObj={
        value:0,
        label:"Show All"
      }
      suppliers.unshift(tempObj);
      console.log("suppliers",suppliers);
			setSupplierList(suppliers);
		}else{
			setSupplierList([]);
			message.error(response.resultObj.message || "No Suppliers");
    	}
		return suppliers;
	};
	const getData = async(currentpage=1, supplierId = supplierID , pageSizevalue=pageSize) =>{
		setLoading(true);
		if(!supplierId && props.tabName===constants.MultiBudgetsAdminTab1){
			let suppliers = supplierList;
			if(!supplierList.length>0){
				suppliers = await getSupplierList();
			}
			supplierId = suppliers.length>0? suppliers[0]?.supplierId : '';
		}
    let url;
    if(supplierId){
      url= WebLinks.SubMultiBudgetByCostCenter+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&supplierId="+supplierId+"&limit="+pageSizevalue+"&pageNo="+currentpage;;
    }else{
      url= WebLinks.SubMultiBudgetByCostCenter+Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)+"&limit="+pageSizevalue+"&pageNo="+currentpage;;
    }
		const todayDate =new Date();
		const response = await APIUtils.apiCall('GET',url);
    	if(!response.isCatch && response.resultObj.statusCode==="2000"){
			//Start: Added unique key to fix all inner tables opening together
			response.resultObj.data.map((item,index)=>{
				item.key=index;
			})
			//end
			setData(response.resultObj.data);
			setTotal(response.resultObj.getCount);
    	}else{
			setData([]);
			message.error(response?.resultObj?.message);
    	}
		setLoading(false);
	};
	const onchangeBudgetTable = (pagination, filters, sorter) =>{
		if(pagination){
			setCurrentPage(pagination);
			setPageSize(filters);
			getData(pagination,supplierID,filters);
		}
	};

  //Function to get priority list
  const getBuyerPrioritylist=async()=>{
    let url = WebLinks.GetBuyerPriorityFilters + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID);    
    const response = await APIUtils.apiCall('GET', url);
    if (!response.isCatch && response.resultObj) {
      setPriorityCheck(response?.resultObj?.data?.length>5?"t":"f");
    } else {
      message.error(response?.resultObj?.message);
    }
  };
	useEffect(()=>{
		getBuyerPrioritylist();
		getData();
	},[])
	useEffect(() => {
		// Update the form with new initial values when the data changes
		const initialValues = data.reduce((acc, item,index) => {
			if(index>0){				
				acc[`thisMonth${index}`] = item.thisMonth;
				acc[`nextMonth${index}`] = item.nextMonth;
				
			}
			return acc;
		}, {});
		form.setFieldsValue(initialValues);
	  }, [data, form]);
	
	return (
		<div>
			<div className='flex flex-wrap justify-between items-center gap-1'>				
					<Space wrap>
					{supplierList.length>0?(
						<>
						<span>Suppliers: </span>
						<Space.Compact>
							
								<Select
								placeholder="Suppliers"
								className="w-[65vw] md:w-60"
								onChange={(e)=>{setSupplierID(e); setCurrentPage(1);setPageSize(100); getData(1,e,100)}}
								defaultValue={0}
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
              }
              showSearch
								options={supplierList}
								/>
						</Space.Compact>
						</>
				):<div/>}
					</Space>
				<span className='text-gray-400'>{constants.BudgetsMsg}</span>
			</div>
			<Spin spinning={Loading}>
				<div className='mt-3 overflow-x-auto'>
					<Form form={form}>
						<Table
							className='budgets-table'
							size="middle"
							columns={columns}
							dataSource={data}
							scroll={{ x: 965, /* y: 450 */ }}
							expandable={{
								// defaultExpandedRowKeys: ['3', '4'],
								expandedRowRender,
								rowExpandable: (record) => false,//record.suppliers !== 'Indeed' && record.suppliers !== 'Total' && record.suppliers !== 'Social Media',
							}}
							pagination={{
								total:total,
								onChange:onchangeBudgetTable,
								defaultPageSize:100
								// pageSize:props.tabName===constants.MultiBudgetsAdminTab1? constants.BudgetpageSize + 1 : constants.pageSize
							}}
						/>
					</Form>
				</div>
			</Spin>
		</div>
	);
};

export default MultiBudgetByCostCenter;
